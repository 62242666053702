import {isImage} from "./helpers/image";

export var type = {
	height:"auto",
	icons:[
		{ id:"attachments", icon:"webix_kanban_icon kbi-file", show:function(obj) { return obj.attachments? obj.attachments.length: false; }, template:"#attachments.length#"},
		{ id:"comments", icon:"webix_kanban_icon kbi-comment" , show:function(obj,kanban) { return !!kanban.config.comments; },
			template:function(obj) { return obj.comments?(obj.comments.length||""):""; }
		},
		{ id:"editor", icon:"webix_kanban_icon kbi-pencil", show:function(obj,kanban) { return (kanban.config.editor && !kanban.config.cardActions);} },
		{ id:"menu", icon:"webix_kanban_icon kbi-cogs", show:function(obj,kanban) { return !!kanban.config.cardActions;} }
	],
	templateTags: function(obj,common,kanban){
		let res = [];
		if(obj.tags){
			let tags = kanban._tags;
			for (let i = 0; i < obj.tags.length; i++){
				let tag = obj.tags[i];
				if (tags.exists(tag))
					tag = tags.getItem(tag).value;
				res.push("<span class='webix_kanban_tag'>"+tag+"</span>");
			}
		}
		return "<div  class='webix_kanban_tags'>" +(res.length?res.join(""):"&nbsp;")+"</div>";
	},
	templateIcons: function(obj,common,kanban){
		var icons = [];
		var icon = null;
		var html = "";
		for (var i = 0; i < common.icons.length; i++){
			icon = common.icons[i];
			if(!icon.show || icon.show(obj,kanban)){
				html = "<span webix_icon_id='"+(icon.id||icon.icon||icon)+"' class='webix_kanban_footer_icon' title='"+(icon.tooltip||"")+"'>";
				html += "<span class='"+(icon.icon||icon)+" webix_icon'></span>";

				if(icon.template){
					html += "<span class='webix_kanban_icon_text'>"+webix.template(icon.template)(obj)+"</span>";
				}
				html += "</span>";
				icons.push(html);
			}
		}
		return "<div  class='webix_kanban_footer_icons'>" + icons.join(" ") + "</div>";
	},
	templateAvatar: function(obj,common,kanban){
		let users = kanban._users;
		let user = (obj.user_id && users.exists(obj.user_id)) ? users.getItem(obj.user_id) : {};
		if (user.image)
			return "<img class='webix_kanban_avatar' src='"+user.image+"' title='"+(user.value||"")+"'>";
		return "<span class='webix_icon webix_kanban_icon kbi-account' title='"+(user.value||"")+"'></span>";
	},
	templateBody: function(obj){
		return obj.text;
	},
	templateAttachments: function(obj){
		if(obj.attachments){
			for (let i in obj.attachments){
				let v = obj.attachments[i];
				let type = v.link.split(".").pop();
				if (isImage(type))
					return "<img class='webix_kanban_attachment' src='"+v.link+"'/>";
			}
		}
		return "";
	},
	templateFooter: function(obj,common,kanban){
		var tags = common.templateTags(obj,common,kanban);
		return (tags?tags:"&nbsp;") + common.templateIcons(obj,common,kanban);
	},
	templateStart:webix.template("<div webix_l_id='#id#' class='{common.classname()} webix_kanban_list_item' style='width:{common.width}px; height:{common.height}px;'>"),
	template:function(obj, common){
		let kanban = webix.$$(common.master);

		var color = kanban._colors.exists(obj.color) ? kanban._colors.getItem(obj.color).color : obj.color;
		var avatar = "<div class='webix_kanban_user_avatar' webix_icon_id='$avatar'>"+common.templateAvatar(obj,common,kanban)+"</div>";
		var body = "<div class='webix_kanban_body'>"+common.templateBody(obj,common,kanban)+avatar+"</div>";
		var attachments = kanban.config.attachments ? common.templateAttachments(obj,common,kanban) : "";
		var footer = "<div class='webix_kanban_footer'>"+common.templateFooter(obj,common,kanban)+ "</div>";
		return "<div class='webix_kanban_list_content'"+(color ? " style='border-left-color:"+color+"'" : "")+">"+attachments+body+footer+"</div>";
	}
};

webix.KanbanView = {
	$kanban:true,
	on_context:{},
	$skin:function(){
		// prevent default list's item skin height
	},
	getKanban(){
		return webix.$$(this.config.master);
	},
	_kanban_event(s, t, i){
		this.attachEvent(s,function(...rest){
			rest[i] = this;
			return  this.getKanban().callEvent(t, rest);
		});
	},
	_fixOrder(){
		this.data.each((a,i) => a.$index = i+1);
	},
	move(sid,tindex,tobj, details){
		tobj = tobj || this;
		details = details || {};
		// normally only one item is dragged
		// still, it possible to enable multi-selection through API
		if (webix.isArray(sid)){
			return webix.DataMove.move.call(this, sid,tindex,tobj, details);
		}

		const statusChange = (tobj !== this);
		const kanban = this.getKanban();
		const item = kanban.getItem(sid);
		const dp = webix.dp.$$(kanban.config.id);

		if (statusChange){
			if (!kanban.callEvent("onBeforeStatusChange", [sid,tobj.config.status,tobj])) return;

			kanban.setListStatus(item, tobj);

			if (dp){
				dp.ignore(() => kanban.updateItem(sid));
			} else kanban.updateItem(sid);
		}

		item.$index = tindex-0.1;
		this._fixOrder();
		tobj._fixOrder();
		webix.DataMove.move.call(tobj, sid, tindex);

		// trigger data saving
		if (dp){
			const update = webix.copy(item);
			update.webix_move_index = tindex;
			update.webix_move_id = tobj.data.order[tindex+1];
			// we need to identify the target list on server side
			// when we have a complex status ( object or function )
			// use separate property
			update.webix_move_parent = tobj.config.serverStatus || tobj.config.status;
			dp.save(sid, "update", update);
		}

		if (statusChange)
			kanban.callEvent("onAfterStatusChange", [sid,tobj.config.status,tobj]);

		return sid;
	},
	_setHandlers(){
		this.attachEvent("onAfterSelect", function(){
			this.eachOtherList(function(list){
				list.unselect();
			});
		});

		this._kanban_event("onBeforeSelect", "onListBeforeSelect", 2);
		this._kanban_event("onAfterSelect", "onListAfterSelect", 1);
		this._kanban_event("onBeforeContextMenu", "onListBeforeContextMenu", 3);
		this._kanban_event("onAfterContextMenu", "onListAfterContextMenu", 3);
		this._kanban_event("onItemClick", "onListItemClick", 3);
		this._kanban_event("onItemDblClick", "onListItemDblClick", 3);
		this._kanban_event("onBeforeDrag", "onListBeforeDrag", 2);
		this._kanban_event("onBeforeDrop", "onListBeforeDrop", 2);
		this._kanban_event("onAfterDrop","onListAfterDrop", 2);
		this._kanban_event("onBeforeDragIn", "onListBeforeDragIn", 2);
		this._kanban_event("onDragOut", "onListDragOut", 2);

		this.on_click.webix_kanban_user_avatar = this._handle_icons;
		this.on_click.webix_kanban_footer_icon = this._handle_icons;
	},
	_handle_icons(e,id,node){
		var icon = node.getAttribute("webix_icon_id");
		var all = this.type.icons;

		//per-icon click handlers
		if (all){
			for (var i=0; i<all.length; i++){
				if (typeof all[i] === "object" && (all[i].id || all[i].icon) === icon){
					if (all[i].click){
						all[i].click.call(this,id,e,node,this);
					}
				}
			}
		}

		if (icon === "$avatar")
			this.getKanban().callEvent("onAvatarClick", [id, e, node, this]);
		else
			this.getKanban().callEvent("onListIconClick", [icon, id, e, node, this]);
	},
	$dragCreate: function(a,e){
		var text = webix.DragControl.$drag(a,e);
		if (!text) return false;
		var drag_container = document.createElement("DIV");
		drag_container.innerHTML=text;
		drag_container.className="webix_drag_zone webix_kanban_drag_zone";
		document.body.appendChild(drag_container);
		return drag_container;
	},
	$dropHTML: function(){
		return "<div class='webix_kanban_drop_inner'></div>";
	},
	eachOtherList:function(code){
		var self = this.config.id;
		var master = this.getKanban();

		master.eachList(function(view){
			if (view.config.id != self)
				code.call(webix.$$(self), view);
		});
	},
	defaults:{
		drag:"move",
		select:true
	}
};