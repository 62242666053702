import "./header";
import "./list";
import "./dataview";
import "./editor";
import "./userlist";
import "./menu";
import "./comments";

import "./locale";

function defaultSetter(a, sub){
	var status = sub.config.status;

	if (typeof status === "object"){
		for (var key in status)
			a[key] = status[key];
	} else if (typeof status === "function"){
		status.call(sub, a, true);
	} else {
		a.status = status;
	}
}
function defaultFilter(sub){
	var status = sub.config.status;

	if (typeof status === "object")
		return function(a){
			for (var key in status){
				if (a[key] != status[key])
					return false;
			}
			return true;
		};

	if (typeof status === "function")
		return function(a){ return status.call(sub, a); };

	return function(a){ return a.status === status; };
}

webix.protoUI({
	name:"kanban",
	defaults:{
		delimiter:","
	},
	$skin:function(){
		this.defaults.type = "space";
	},
	$init(config){
		this.$view.className += " webix_kanban";

		this.data.provideApi(this, true);
		this.data.scheme({
			$change:(obj) =>{
				if (typeof obj.tags === "string")
					obj.tags = this._strToArr(obj.tags);
			}
		});
		this._destroy_with_me = [];

		this._statuses = new webix.DataCollection();
		this._destroy_with_me.push(this._statuses);

		this._tags = this._data_unification(config.tags);
		this._users = this._data_unification(config.users);
		this._colors = this._data_unification(config.colors);
		this.$ready.push(function(){
			this.reconstruct();
			this._initEditor();
			this._initUserList();
			this._initMenu();
			this._initComments();
			this.data.attachEvent("onStoreUpdated",
				(id, data, mode) => this._applyOrder(id, data, mode));
			this.data.attachEvent("onIdChange",
				(oldid, newid) => {
					this.getOwnerList(oldid).data.changeId(oldid, newid);
				});
			this.attachEvent("onDestruct", () => {
				for (let i = 0; i < this._destroy_with_me.length; i++)
					this._destroy_with_me[i].destructor();
			});
		});

		//override default api of datastore
		this.serialize = this._serialize;
	},
	_strToArr(value){
		if (value){
			return value.split(this.config.delimiter);
		}
		return [];
	},
	getTags(){
		return this._tags;
	},
	getUsers(){
		return this._users;
	},
	getColors(){
		return this._colors;
	},
	getStatuses(){
		return this._statuses.serialize();
	},
	cardActions_setter(value){
		if (value === true) value = ["edit", "copy", "remove"];
		if (webix.isArray(value)){
			return value.map((v) => {
				return { id:v, value:webix.i18n.kanban.menu[v]||v };
			});
		}
	},
	showEditor(obj){
		let editor = this.getEditor();
		if (this.callEvent("onBeforeEditorShow", [editor, obj]) && editor){
			editor.setValues(obj);
			editor.show();
			this.callEvent("onAfterEditorShow", [editor, obj]);
		}
	},
	copy(id){
		if (this.callEvent("onBeforeCopy", [id])){
			let item = webix.copy(this.getItem(id));
			delete item.id;
			item.text = webix.i18n.kanban.copy + " " + (item.text || "");
			let sid = this.add(item);
			let list = this.getOwnerList(sid);
			list.move(sid, list.getIndexById(id)+1, list);

			this.callEvent("onAfterCopy", [id]);
		}
	},
	_removeCard(id){
		var promise = webix.promise.defer();

		if (webix.i18n.kanban.confirm){
			webix.confirm({
				text:webix.i18n.kanban.confirm,
				callback:(result) => {
					if (result) {
						this.remove(id);
						promise.resolve();
					}
				}
			});
		} else {
			this.remove(id);
			promise.resolve();
		}

		return promise;
	},
	_data_unification(value){
		if (value && value.getItem)
			return value;
		else {
			let data = new webix.DataCollection();
			this._destroy_with_me.push(data);
			if(value && typeof value === "string")
				data.load(value);
			else
				data.parse(value||[]);
			return data;
		}
	},
	getEditor(){
		return webix.$$(this._editor);
	},
	getUserList(){
		return webix.$$(this._userList);
	},
	getMenu(){
		return webix.$$(this._menu);
	},
	getComments(){
		return webix.$$(this._comments);
	},
	_initEditor(){
		if (this.config.editor){
			let editor = webix.ui( {view:"kanbaneditor", master:this.config.id} );

			this._editor = editor.config.id;
			this._destroy_with_me.push(editor);

			this.attachEvent("onListIconClick", (icon, id) => {
				if (icon === "editor") this.showEditor(webix.copy( this.getItem(id) ));
			});
			this.attachEvent("onListItemDblClick", (id) => this.showEditor(webix.copy( this.getItem(id) )));
		}
	},
	_initUserList(){
		if (this.config.userList){
			let userlist = typeof this.config.userList === "object"?this.config.userList:{};
			webix.extend(userlist, {view:"kanbanuserlist", masterId:this.config.id, data:this._users}, true);
			userlist = webix.ui(userlist);

			this._userList = userlist.config.id;
			this._destroy_with_me.push(userlist);
			
			this.attachEvent("onAvatarClick", (id, e, node) => {
				let userList = this.getUserList();
				userList.setContext({id:id, user_id:this.getItem(id).user_id});
				userList.show(node);
			});
		}
	},
	_initMenu(){
		if (this.config.cardActions){
			let menu = webix.ui( {view:"kanbanmenu", data:this.config.cardActions, masterId:this.config.id} );

			this._menu = menu.config.id;
			this._destroy_with_me.push(menu);

			this.attachEvent("onListIconClick", (icon, id, e, node) => {
				if (icon === "menu"){
					let menu = this.getMenu();
					menu.setContext( {id:id} );
					menu.show(node);
				}
			});
		}
	},
	_initComments(){
		if (this.config.comments){
			let body = typeof this.config.comments === "object"?this.config.comments:{};
			body = webix.extend({width:400, height:400}, body, true);
			webix.extend(body, { view:"comments", users:this._users }, true);
			let comments = webix.ui( {view:"kanbanchat", body:body, masterId:this.config.id} );

			this._comments = comments.config.id;
			this._destroy_with_me.push(comments);

			this.attachEvent("onListIconClick", (icon, id, e, node) => {
				if (icon === "comments" && this.callEvent("onBeforeCommentsShow", [id, e, node])){
					let chat = this.getComments();
					let pos = webix.html.offset(node);
					pos.y += pos.height;
					
					chat.hide();
					let comments = chat.getBody();
					let data = this.getItem(id).comments || [];

					comments.parse( webix.copy(data) );
					chat.setContext( {id:id} );
					chat.show(pos);
				}
			});
		}
	},
	_serialize(){
		var d = [];
		this.eachList(function(l){ d = d.concat(l.serialize()); });
		return d;
	},
	_applyOrder(id, data, mode){
		if (!id){
			this._syncData();
			return;
		}

		if (mode == "add"){
			this._assignList(data);
			this.getOwnerList(id).add(data);
		} else if (mode === "delete"){
			this._sublists[data.$list].remove(id);
		} else if (mode === "update" || mode === "paint"){
			let list = data.$list;
			this._assignList(data);
			if (list === data.$list)
				this.getOwnerList(id).updateItem(id, data);
			else {
				this._sublists[list].remove(id);
				this.getOwnerList(id).add(data);
			}
		}
	},
	setListStatus(obj, list){
		for (var i=0; i<this._sublists.length; i++){
			if (this._sublists[i] === list){
				defaultSetter(obj, list);
				return;
			}
		}
	},
	reconstruct(){
		this._prepareLists();
		this._syncData();
	},
	_prepareLists(){
		this._sublists = [];
		this._subfilters = [];
		let statuses = [];
		let views = this.queryView(a => a.$kanban, "all");

		for (let i = 0; i < views.length; i++) {
			let sub = views[i];
			if ( sub.config.view !== "kanbanheader")
				this._sublists.push(sub);
			else sub.config.master = this.config.id;
		}

		for (let i = 0; i < this._sublists.length; i++) {
			let sub = this._sublists[i];
			this._subfilters[i] = defaultFilter(sub);

			if (this.config.icons){
				sub.type.icons = sub.type.icons || this.config.icons;
			}

			let value = sub.config.name || (typeof sub.config.status === "string" && sub.config.status ?
				sub.config.status[0].toUpperCase() + sub.config.status.slice(1) : sub.config.id);
			statuses.push( {id:i.toString(), value:value} );

			sub.config.master = this.config.id;
			sub.type.master = this.config.id;
		}

		this._statuses.clearAll();
		this._statuses.parse(statuses);
	},
	_syncData(){
		var i, sets = [];
		for (i=0; i<this._sublists.length; i++) sets[i] = [];

		this.data.each(function(item){
			let j = this._assignList(item);
			if (j >= 0)
				sets[j].push(item);
		}, this);

		for (i=0; i<this._sublists.length; i++){
			var data = sets[i];
			if (data.length && data[0].$index)
				data.sort( (a,b) => a.$index > b.$index ? 1 : -1 );

			this._sublists[i].clearAll();
			this._sublists[i].data.importData(data);
		}
	},
	_assignList(data){
		for (var i = 0; i < this._sublists.length; i++) {
			if (this._subfilters[i](data)){
				return data.$list = i;
			}
		}
		return -1;
	},
	getSelectedId(){
		var selected = null;
		this.eachList(function(list){ selected = list.getSelectedId() || selected; });
		return selected;
	},
	select(id){
		this.getOwnerList(id).select(id);
	},
	getOwnerList(id){
		var item = this.getItem(id);
		return item ? this._sublists[item.$list] : null;
	},
	eachList(code){
		for (var i=0; i<this._sublists.length; i++){
			code.call(this, this._sublists[i], this._sublists[i].config.status);
		}
	}
}, webix.DataLoader, webix.EventSystem, webix.ui.headerlayout );